<template>
  <div class="dashcontainer">
    <Header></Header>
    <MerchantSetup :data="merchantSetupList" />
    <div class="maincontainer">
      <div>
        <span class="hello mr-3">
          {{ lbl['dashboard-hello'] }}
          {{ businessName ? businessName : '' }}</span
        >
        <span class="lastupdate">
          {{ lbl['dashboard-last-update'] }} {{ currentDate }}</span
        >
      </div>
      <CounterBox :data="counterItem" ref="CounterBoxMain" />
      <DashboardContainer
        :data="{ title: lbl['dashboard-number-of-membership-title'] }"
        @onChange="onChangeMainChart($event)"
      >
        <template v-slot:button>
          <a-button
            class="d-flex align-items-center"
            @click="onChangeModal(true, 'showModalInsights')"
          >
            <img :src="getImage('dashboard', 'folder')" alt="" class="mr-2" />
            <span>{{ lbl['dashboard-user-insights-button'] }}</span>
          </a-button>
          <a-button class="d-flex align-items-center" @click="mapExportData">
            <img
              :src="getImage('dashboard', 'filearrowdown')"
              alt=""
              class="mr-2"
            />
            <span>{{ lbl['dashboard-export-file-button'] }}</span>
          </a-button>
        </template>
        <template>
          <div class="chart mt-3">
            <a-empty v-if="!memberSeriesState" class="pd-chart" />
            <apexchart
              v-else
              width="100%"
              height="400px"
              type="bar"
              :options="memberOptions"
              :series="memberSeries"
            />
            <div class="d-flex my-5">
              <div class="w-50">
                <div class="header d-flex align-items-center">
                  <img :src="getImage('dashboard', 'gender')" alt="gender" />{{
                    lbl['dashboard-insight-user-info-sex']
                  }}
                </div>
                <a-empty v-if="!genderSeries.length" class="pd-chart" />
                <apexchart
                  v-else
                  width="100%"
                  height="400px"
                  type="donut"
                  :options="genderOptions"
                  :series="genderSeries"
                />
              </div>
              <div class="w-50">
                <div class="header d-flex align-items-center">
                  <img :src="getImage('dashboard', 'baby')" alt="age" />{{
                    lbl['dashboard-insight-age']
                  }}
                </div>
                <a-empty v-if="!ageSeries[0].data.length" class="pd-chart" />
                <apexchart
                  v-else
                  width="100%"
                  height="400px"
                  type="bar"
                  :options="ageOptions"
                  :series="ageSeries"
                />
              </div>
            </div>
            <div>
              <div
                class="w-100 d-flex align-items-center justify-content-between"
              >
                <div class="header d-flex align-items-center">
                  <img
                    :src="getImage('dashboard', 'fire')"
                    alt="Active Member"
                  />{{ lbl['dashboard-active-member'] }}
                </div>
                <a-button
                  class="d-flex align-items-center mb-3"
                  @click="exportActiveUser"
                >
                  <img
                    :src="getImage('dashboard', 'filearrowdown')"
                    alt=""
                    class="mr-2"
                  />
                  <span>{{ lbl['dashboard-export-file-button'] }}</span>
                </a-button>
              </div>
              <a-empty
                v-if="!activeMemberSeries[0].data.length"
                class="pd-chart"
              />
              <apexchart
                v-else
                width="100%"
                height="400px"
                type="area"
                :options="activeMemberOptions"
                :series="activeMemberSeries"
              />
            </div>
          </div>
          <CounterBox :data="activeMember" ref="CounterBoxActiveMember" />
        </template>
      </DashboardContainer>
      <DashboardContainer
        :data="{ title: lbl['user-level-point-unit'] }"
        @onChange="onChangePointChart($event)"
      >
        <template v-slot:button>
          <a-button
            class="d-flex align-items-center"
            @click="onChangeModal(true, 'showModalTransactions')"
          >
            <span>{{ lbl['dashboard-transactions-point-list-header'] }}</span>
          </a-button>
          <a-button class="d-flex align-items-center" @click="exportPointChart">
            <img
              :src="getImage('dashboard', 'filearrowdown')"
              alt=""
              class="mr-2"
            />
            <span>{{ lbl['dashboard-export-file-button'] }}</span>
          </a-button>
        </template>
        <div class="chart mt-3">
          <a-empty v-if="!pointSeriesState" class="my-5 py-5" />
          <apexchart
            v-else
            width="100%"
            height="400px"
            :options="pointOption"
            :series="pointSeries"
          />
        </div>
      </DashboardContainer>
      <RankContainer class="mb-5" :data="rankListData" />
    </div>

    <CookieAccept />

    <!-- Show Modal 2 -->
    <ExpirePackage
      :isOpenModal="expirePackage.isOpenModal"
      :modalType="expirePackage.modalType"
      :title="expirePackage.title"
      :details="expirePackage.details"
      :showbackto="expirePackage.showbackto"
      :backto="expirePackage.backto"
      :confirm="expirePackage.confirm"
      :onConfirm="expirePackage.onConfirm"
      :onCancel="expirePackage.onCancel"
      :fontSize="'20'"
    />

    <!-- Show Modal 3 (isWelcomeBack == true) -->
    <WelcomeBack
      :isOpenModal="welcomeBack.isOpenModal"
      :title="welcomeBack.title"
      :details="welcomeBack.details"
      :onCancel="welcomeBack.onCancel"
      :fontSize="'20'"
    />

    <!-- Show Modal 4 (isChangePackage == true) -->
    <PackageChanged
      :isOpenModal="packageChanged.isOpenModal"
      :title="packageChanged.title"
      :details="packageChanged.details"
      :lifeTime="packageChanged.lifeTime"
      :payment="packageChanged.payment"
      :imgHistory="packageChanged.imgHistory"
      :imgCurrent="packageChanged.imgCurrent"
      :onCancel="packageChanged.onCancel"
      :onView="packageChanged.onView"
      :fontSize="'20'"
    />

    <!-- Show Modal 5 -->
    <Notification
      :isOpenModal="notificationAccountSetting.isOpenModal"
      :modalType="notificationAccountSetting.modalType"
      :title="notificationAccountSetting.title"
      :details="notificationAccountSetting.details"
      :backto="notificationAccountSetting.backto"
      :confirm="notificationAccountSetting.confirm"
      :onConfirm="notificationAccountSetting.onConfirm"
      :onCancel="notificationAccountSetting.onCancel"
      @passNotify="passNotify($event)"
    />

    <!-- Show Modal 6 -->
    <Announcement
      :isOpenModal="announcement.isOpenModal"
      :data="announcement.data"
      :onCancel="announcement.onCancel"
      :fontSize="'20'"
      @onCancelAnnouncement="onCancelAnnouncement($event)"
    />

    <Insights
      :show="showModalInsights"
      :data="dataInsights"
      @changeInsights="onChangeModal($event, 'showModalInsights')"
    />
    <TransactionsEDC
      :show="showModalTransactions"
      :data="dataTransactions"
      @changeTransactions="onChangeModal($event, 'showModalTransactions')"
    />
    <alertUpgradePackage
      :visible="showAlertUpgradepackage"
      :title="alertUpgradeTitle"
      :internal="actionUpgrade.internalPage"
      :page-name="actionUpgrade.pageName"
      @close="showAlertUpgradepackage = !showAlertUpgradepackage"
    />
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import BzbsDashboard from '../../core/Dashboard/BzbsDashboard'
import Vue from 'vue'
import Account from '@/helper/AccountHelper.js'
import PackageHelper from '@/helper/PackageHelper.js'
import BzbsPackage from '@/core/Package/service/BzbsPackage'
import BzbsCart from '@/core/Cart/service/BzbsCart'
import alertUpgradePackage from '@/components/Modal/Component/alertUpgradePackage'
import CookieAccept from '@/components/Layout/CookieAccept'
import WelcomeBack from '@/components/Modal/Component/WelcomeBack'
import ExpirePackage from '@/components/Modal/Component/confirmmodal2'
import PackageChanged from '@/components/Modal/Component/PackageChanged'
import Notification from '@/components/Modal/Component/confirmmodal2'
import Announcement from '@/components/Modal/Component/Announcement'
import MerchantSetup from './components/MerchantSetup.vue'
import CounterBox from './components/CounterBox'
import DashboardContainer from './components/DashboardContainer.vue'
import RankContainer from './components/RankContainer'
import Insights from '@/module/components/dashboard/popup/Insights'
import TransactionsEDC from '@/module/components/dashboard/popup/TransactionsEDC'
import moment from 'moment'
import XLSX from 'xlsx'

export default {
  name: 'Dashboard',
  components: {
    Header,
    MerchantSetup,
    CounterBox,
    DashboardContainer,
    RankContainer,
    Insights,
    TransactionsEDC,
    CookieAccept,
    WelcomeBack,
    ExpirePackage,
    PackageChanged,
    Notification,
    Announcement,
    alertUpgradePackage,
  },
  mixins: [Mixin],
  data() {
    return {
      welcomeBack: {
        title: '',
        details: '',
        onCancel: null,
        isOpenModal: false,
      },
      notificationAccountSetting: {
        title: '',
        details: '',
        modalType: '',
        backto: '',
        confirm: '',
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      expirePackage: {
        title: '',
        details: '',
        modalType: '',
        backto: '',
        confirm: '',
        showbackto: true,
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      packageChanged: {
        title: '',
        details: '',
        lifeTime: '',
        payment: '',
        imgHistory: null,
        imgCurrent: null,
        onCancel: null,
        onView: null,
        isOpenModal: false,
      },
      announcement: {
        data: [],
        onCancel: null,
        isOpenModal: false,
      },
      showModalInsights: false,
      dataInsights: {},
      showModalTransactions: false,
      dataTransactions: {},
      mainChartData: {},
      pointChartData: {},
      merchantSetupList: {
        PointRatio: {
          title: 'Point setup:',
          info: 'แสดงเรทการให้คะแนนของร้านในปัจจุบัน (Day-1)',
          value: 0,
        },
        ActiveReward: {
          title: 'Active Reward:',
          info: 'จำนวนของรางวัลที่เปิดใช้งานอยู่​',
          value: 0,
        },
        FriendGetFriendCount: {
          title: 'Friend get friends:',
          info: 'จำนวนลูกค้าใหม่ที่เชิญได้จากกิจกรรมชวนเพื่อน (ตั้งแต่สร้างร้านมา)',
          value: 0,
        },
        BranchCount: { title: 'Your branch:', info: '', value: 0 },
      },
      counterItem: {
        TotalUser: {
          icon: 'totalmember',
          title: 'Total Member',
          value: 0,
          et: 'Member',
          class: 'span2',
          info: 'จำนวนลูกค้าทั้งหมด ตั้งแต่สร้างร้านค้า (หักลบ user ที่ยกเลิกสมาชิก)​',
        },
        PointIssuer: {
          icon: 'coin',
          title: 'Total Point Issue',
          value: 0,
          et: 'Points',
          class: 'span2',
          info: 'จำนวนคะแนนทั้งหมด ที่มีการให้แก่ลูกค้า​​​',
        },
        AveragePointOnHand: {
          icon: 'pointonhand',
          title: 'Avg. Point on hand/Member',
          value: 0,
          et: 'Points',
          class: '',
          info: 'ค่าเฉลี่ยจำนวนคะแนนที่ลูกค้าแต่ละคนมี​',
        },
        AverageTicketSize: {
          icon: 'ticket',
          title: 'Avg. ticket size',
          value: 0,
          et: 'Baht',
          class: '',
          info: 'ค่าเฉลี่ยต่อการซื้อสินค้าใน 1 ครั้ง​',
        },
        TotalSale: {
          icon: 'piggybank',
          title: 'Total Sales',
          value: 0,
          et: 'Baht',
          class: '',
          info: 'ยอดขาย spending ที่ใช้ในการให้คะแนน ทั้งหมด​​​',
        },
        ExtraRevenue: {
          icon: 'bank',
          title: 'Extra Revenue From CRM PLUS',
          value: 0,
          et: 'Baht',
          class: '',
          info: '​​ยอดรายได้ที่เพิ่มขึ้นหลังจากใช้งาน  CRM PLUS  (คำนวณจากการซื้อ ครั้งที่ 2++ ของuser)​',
        },
      },
      activeMember: {
        AverageActiveMember: {
          icon: 'totalmember',
          title: 'Average active Member',
          value: 430653,
          et: 'Member',
          class: '',
        },
        AverageTicketSize: {
          icon: 'coin',
          title: 'Average ticket size',
          value: 1168,
          et: 'THB',
          class: '',
        },
        AverageUniqueRedeemUser: {
          icon: 'pointonhand',
          title: 'Average Unique Redeem Member',
          value: 75,
          et: 'Member',
          class: '',
        },
        AverageUniqueEarnedUser: {
          icon: 'ticket',
          title: 'Average Unique Earn point Member',
          value: 67,
          et: 'Member',
          class: '',
        },
      },
      rankListData: [
        {
          icon: 'reward',
          page: 'RewardRank',
          title: 'Reward Redemption Rank',
          getlist: this.getRewardRedemtionRank,
          data: [],
        },
        {
          icon: 'branch',
          page: 'BranchDetail',
          title: 'Branch/Terminal Givepoint',
          getlist: this.getBranchRank,
          data: [],
        },
      ],
      memberOptions: {
        chart: {
          id: 'numberofmembership',
          stacked: true,
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          background: '#fff',
          fontFamily:
            this.currentLocale == 'th' ? 'AnuphanRegular' : 'RobotoRegular',
          foreColor: '#000',
        },
        colors: ['#006EFF', '#00e396'],
        xaxis: {
          categories: [],
        },
        legend: {
          position: 'top',
        },
      },
      memberSeries: [],
      genderOptions: {
        chart: {
          id: 'numberofmembership',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          background: '#fff',
          fontFamily:
            this.currentLocale == 'th' ? 'AnuphanRegular' : 'RobotoRegular',
          foreColor: '#000',
        },
        labels: [],
      },
      genderSeries: [],
      ageOptions: {
        chart: {
          id: 'numberofmembership',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          background: '#fff',
          fontFamily:
            this.currentLocale == 'th' ? 'AnuphanRegular' : 'RobotoRegular',
          foreColor: '#000',
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        colors: ['#006EFF', '#00e396'],
        xaxis: {
          categories: [],
        },
        legend: {
          position: 'top',
        },
      },
      ageSeries: [
        {
          name: '',
          data: [],
        },
      ],
      activeMemberOptions: {
        chart: {
          id: 'numberofmembership',
          stacked: true,
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          background: '#fff',
          fontFamily:
            this.currentLocale == 'th' ? 'AnuphanRegular' : 'RobotoRegular',
          foreColor: '#000',
        },
        colors: ['#006EFF', '#00e396'],
        xaxis: {
          categories: [],
        },
        legend: {
          position: 'top',
        },
      },
      activeMemberSeries: [{ name: '', data: [] }],
      pointOption: {
        chart: {
          height: 350,
          type: 'line',
        },
        legend: {
          position: 'top',
        },
        stroke: {
          width: [0, 4],
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: [],
        // xaxis: {
        //     type: 'datetime'
        // },
        yaxis: [
          {
            title: {
              text: 'Earn',
            },
          },
          {
            opposite: true,
            title: {
              text: 'Burn',
            },
          },
        ],
      },
      pointSeries: [
        {
          name: 'Earn',
          type: 'column',
          data: [],
        },
        {
          name: 'Burn',
          type: 'line',
          data: [],
        },
      ],
      showAlertUpgradepackage: false,
      alertUpgradeTitle: '',
      actionUpgrade: {
        internalPage: true,
        pageName: 'Dashboard',
      },
      businessName: '',
      activeUserData: [],
    }
  },
  computed: {
    currentDate() {
      return moment().format('DD/MM/YYYY - HH:mm')
    },
    memberSeriesState() {
      if (this.memberSeries) {
        return this.memberSeries.every(item => item.data.length)
      } else {
        return false
      }
    },
    pointSeriesState() {
      if (this.pointSeries) {
        return this.pointSeries.every(item => item.data.length)
      } else {
        return false
      }
    },
  },
  created() {
    this.merchantSetupList = {
      PointRatio: {
        title: this.lbl['dashboard-point-setup'],
        info: this.lbl['dashboard-point-setup-info'],
        value: 0,
      },
      ActiveReward: {
        title: this.lbl['dashboard-active-reward'],
        info: this.lbl['dashboard-active-reward-info'],
        value: 0,
      },
      FriendGetFriendCount: {
        title: this.lbl['dashboard-fgf'],
        info: this.lbl['dashboard-fgf-info'],
        value: 0,
      },
      BranchCount: {
        title: this.lbl['dashboard-your-branch'],
        info: this.lbl['dashboard-your-branch-info'],
        value: 0,
      },
    }
    this.counterItem = {
      TotalUser: {
        icon: 'totalmember',
        title: this.lbl['dashboard-total-member'],
        value: 0,
        et: this.lbl['permission-type-member'],
        info: this.lbl['dashboard-total-member-info'],
        class: 'span2',
      },
      PointIssuer: {
        icon: 'coin',
        title: this.lbl['dashboard-total-point-issue'],
        value: 0,
        et: 'Points',
        info: this.lbl['dashboard-total-point-issue-info'],
        class: 'span2',
      },
      AveragePointOnHand: {
        icon: 'pointonhand',
        title: this.lbl['dashboard-avg-point-on-hand'],
        value: 0,
        et: 'Points',
        info: this.lbl['dashboard-avg-point-on-hand-info'],
        class: '',
      },
      AverageTicketSize: {
        icon: 'ticket',
        title: this.lbl['dashboard-avg-ticket-size'],
        value: 0,
        et: 'Baht',
        info: this.lbl['dashboard-avg-ticket-size-info'],
        class: '',
      },
      TotalSale: {
        icon: 'piggybank',
        title: this.lbl['dashboard-total-sales'],
        value: 0,
        et: 'Baht',
        info: this.lbl['dashboard-total-sales-info'],
        class: '',
      },
      ExtraRevenue: {
        icon: 'bank',
        title: this.lbl['dashboard-extra-revenue-from-crm-plus'],
        value: 0,
        et: 'Baht',
        info: this.lbl['dashboard-extra-revenue-from-crm-plus-info'],
        class: '',
      },
    }
    this.activeMember = {
      AverageActiveMember: {
        icon: 'totalmember',
        title: this.lbl['dashboard-avg-active-member'],
        info: this.lbl['dashboard-avg-active-member-info'],
        value: 430653,
        et: this.lbl['permission-type-member'],
        class: '',
      },
      AverageTicketSize: {
        icon: 'coin',
        title: this.lbl['dashboard-avg-ticket-size'],
        info: this.lbl['dashboard-avg-ticket-size-info'],
        value: 1168,
        et: this.lbl['third-party-thb'],
        class: '',
      },
      AverageUniqueRedeemUser: {
        icon: 'pointonhand',
        title: this.lbl['dashboard-avg-unique-redeem-member'],
        info: this.lbl['dashboard-avg-unique-redeem-member-info'],
        value: 75,
        et: this.lbl['permission-type-member'],
        class: '',
      },
      AverageUniqueEarnedUser: {
        icon: 'ticket',
        title: this.lbl['dashboard-avg-unique-earn-point-member'],
        info: this.lbl['dashboard-avg-unique-earn-point-member-info'],
        value: 67,
        et: this.lbl['permission-type-member'],
        class: '',
      },
    }
    this.rankListData = [
      {
        icon: 'reward',
        page: 'RewardRank',
        title: this.lbl['dashboard-reward-redemption-rank'],
        getlist: this.getRewardRedemtionRank,
        data: [],
      },
      {
        icon: 'branch',
        page: 'BranchDetail',
        title: this.lbl['dashboard-branch-and-terminal-givepoint'],
        getlist: this.getBranchRank,
        data: [],
      },
    ]
    this.getBanner()
    this.getSummaryDashboard()
    this.dataInsights = {
      startdate: moment().add(-29, 'd').format('YYYY-MM-DD'),
      enddate: moment().format('YYYY-MM-DD'),
    }
    this.getMemberChart()
    this.getGenderChart()
    this.getAgeChart()
    this.getActiveMemberChart()
    this.getPointChart()

    this.beforeinit()
  },
  methods: {
    onChangeModal(data, type) {
      var onCondition =
        type == 'showModalInsights' || type == 'showModalTransactions'
      console.log(type == 'showModalTransactions')
      if (onCondition && this.crmPackage.IsFreePackage) {
        this.showAlertUpgradepackage = true
        this.alertUpgradeTitle =
          type == 'showModalTransactions'
            ? this.lbl['alert-upgrade-package-for-transactions-text']
            : this.lbl['alert-upgrade-package-for-insights-text']
        this.actionUpgrade = {
          internalPage: true,
          pageName: 'Package',
        }
      } else {
        this[type] = data
      }
    },
    onChangeDate(data, type) {
      this[type] = {
        startdate: data[0].format('YYYY-MM-DD'),
        enddate: data[1].format('YYYY-MM-DD'),
      }
    },
    getBanner() {
      BzbsDashboard.getBanner()
        .then(res => {
          if (res.data.success) {
            let bannerData = res.data.data.Data
            Object.keys(this.merchantSetupList).forEach(key => {
              this.merchantSetupList[key].value = bannerData[key]
            })
          } else {
            console.log(
              'getBanner err :: ',
              res.data.code,
              ': ',
              res.data.message,
            )
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getSummaryDashboard() {
      BzbsDashboard.getSummaryDashboard()
        .then(res => {
          let getSummaryData = res.data.data
          Object.keys(this.counterItem).forEach(key => {
            this.counterItem[key].value = getSummaryData[key]
          })
          this.$refs.CounterBoxMain.trickerCounter()
          this.$forceUpdate()
        })
        .catch(err => {
          console.log(err)
        })
    },
    onChangePointChart(date) {
      this.getPointChart(date)
    },
    onChangeMainChart(date) {
      this.dataInsights = {
        startdate: date[0].format('YYYY-MM-DD'),
        enddate: date[1].format('YYYY-MM-DD'),
      }
      this.getMemberChart(date)
      this.getGenderChart(date)
      this.getAgeChart(date)
      this.getActiveMemberChart(date)
    },
    getMemberChart(date = [moment().subtract(30, 'days'), moment()]) {
      BzbsDashboard.getMemberChart({
        startdate: date[0].format('YYYY-MM-DD'),
        enddate: date[1].format('YYYY-MM-DD'),
        filter: 'Custom',
      })
        .then(res => {
          let memberChartDataExistingUser = {}
          let memberChartDataNewUser = {}
          this.mainChartData.memberChartData = res.data.data
          res.data.data
            .sort((a, b) => a.RegisteredOn - b.RegisteredOn)
            .forEach(item => {
              let date = moment(item.RegisteredOn * 1000).format('DD/MM/YYYY')
              if (memberChartDataExistingUser[date]) {
                memberChartDataExistingUser[date] =
                  memberChartDataExistingUser[date] + item.ExistingUser
                memberChartDataNewUser[date] =
                  memberChartDataNewUser[date] + item.NewUser
              } else {
                memberChartDataExistingUser[date] = item.ExistingUser
                memberChartDataNewUser[date] = item.NewUser
              }
            })
          let labels = []
          let seriesExistingUser = { name: 'Existing Member', data: [] }
          Object.keys(memberChartDataExistingUser).forEach(item => {
            seriesExistingUser.data.push(memberChartDataExistingUser[item])
            labels.push(item)
          })
          let seriesNewUser = { name: 'New Member', data: [] }
          Object.keys(memberChartDataNewUser).forEach(item => {
            seriesNewUser.data.push(memberChartDataNewUser[item])
            // labels.push(item)
          })
          this.memberSeries = [seriesExistingUser, seriesNewUser]
          this.memberOptions = {
            ...this.memberOptions,
            xaxis: { categories: labels },
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getGenderChart(date = [moment().subtract(30, 'days'), moment()]) {
      BzbsDashboard.getGenderChart({
        startdate: date[0].format('YYYY-MM-DD'),
        enddate: date[1].format('YYYY-MM-DD'),
        filter: 'Custom',
      })
        .then(res => {
          this.mainChartData.genderChartData = res.data.data
          let genderChartData = res.data.data
          let series = []
          let labels = []
          genderChartData.forEach(item => {
            series.push(item.NumberOfUser)
            labels.push(item.Gender)
          })
          this.genderSeries = [...series]
          this.genderOptions = { ...this.genderOptions, labels }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getAgeChart(date = [moment().subtract(30, 'days'), moment()]) {
      BzbsDashboard.getAgeChart({
        startdate: date[0].format('YYYY-MM-DD'),
        enddate: date[1].format('YYYY-MM-DD'),
        filter: 'Custom',
      })
        .then(res => {
          this.mainChartData.ageChartData = res.data.data
          let ageChartData = {}
          res.data.data
            .sort((a, b) => a.AgeRange.localeCompare(b.AgeRange))
            .forEach(item => {
              if (ageChartData[item.AgeRange]) {
                ageChartData[item.AgeRange] =
                  ageChartData[item.AgeRange] + item.NumberOfUser
              } else {
                ageChartData[item.AgeRange] = item.NumberOfUser
              }
            })
          let series = { name: 'User', data: [] }
          let labels = []
          Object.keys(ageChartData).forEach(item => {
            series.data.push(ageChartData[item])
            labels.push(item)
          })
          this.ageSeries = [series]
          this.ageOptions = {
            ...this.ageOptions,
            xaxis: { categories: labels },
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getActiveMemberChart(date = [moment().subtract(30, 'days'), moment()]) {
      BzbsDashboard.getActiveMemberChart({
        startdate: date[0].format('YYYY-MM-DD'),
        enddate: date[1].format('YYYY-MM-DD'),
        filter: 'Custom',
      })
        .then(res => {
          this.mainChartData.activememberChartData =
            res.data.data.TrendActiveMembers
          let activememberChartData = res.data.data
          let series = { name: 'User', data: [] }
          let labels = []
          Object.keys(activememberChartData).forEach(item => {
            if (item !== 'TrendActiveMembers') {
              this.activeMember[item].value = activememberChartData[item] || 0
            }
          })
          this.$refs.CounterBoxActiveMember.trickerCounter()
          activememberChartData.TrendActiveMembers.forEach(item => {
            series.data.push(item.NumberOfActiveUser)
            labels.push(`${item.Day}/${item.Month}/${item.Year}`)
          })
          this.activeMemberSeries = [series]
          this.activeMemberOptions = {
            ...this.activeMemberOptions,
            xaxis: { categories: labels },
          }
          try {
            this.activeUserData = res.data.data.TrendActiveMembers.flatMap(
              member =>
                member.ActiveUserDetails.map(detail => ({
                  ActiveDate: detail.ActiveDate,
                  UserId: detail.UserId,
                })),
            )
          } catch {
            this.activeUserData = []
          }
          console.log(this.activeUserData)
        })
        .catch(err => {
          console.log(err)
        })
    },
    exportActiveUser() {
      this.exportFile(this.activeUserData, 'activeUser')
    },
    getPointChart(date = [moment().subtract(3, 'month'), moment()]) {
      BzbsDashboard.getPointChart({
        startdate: date[0].format('YYYY-MM-DD'),
        enddate: date[1].format('YYYY-MM-DD'),
        filter: 'Month',
      })
        .then(res => {
          this.pointChartData = res.data.data.sort((a, b) => a.Year - b.Year)
          let earnSeries = {
            name: 'Earn',
            type: 'column',
            data: [],
          }
          let burnSeries = {
            name: 'Burn',
            type: 'line',
            data: [],
          }
          let labels = []
          this.pointChartData.forEach(item => {
            earnSeries.data.push(item.EarnPoint || 0)
            burnSeries.data.push(item.BurnPoin || 0)
            labels.push(`${item.Month || 1}/${item.Year}`)
          })
          this.pointSeries = [earnSeries, burnSeries]
          this.pointOption = { ...this.pointOption, labels }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getRewardRedemtionRank(date = [moment().subtract(30, 'days'), moment()]) {
      BzbsDashboard.getRewardRedemtionRank({
        startdate: date[0].format('YYYY-MM-DD'),
        enddate: date[1].format('YYYY-MM-DD'),
      })
        .then(res => {
          let redemtionRank = res.data.data.slice(0, 5)
          this.rankListData[0].data = []
          redemtionRank.forEach((item, index) => {
            this.rankListData[0].data.push({
              rank: index + 1,
              img: item.PictureUrl,
              showImg: true,
              badge: [item.Campaignstatus, item.Campaigntypes],
              text: item.Campaignname,
              id: item.Campaignid,
              adon: [
                {
                  key: 'Quantity',
                  text: this.lbl['dashboard-quanlity'],
                  value: item.Quantity ? item.Quantity : 0,
                },
                {
                  key: 'Redeemed',
                  text: this.lbl['dashboard-insight-user-history-redeem-tab'],
                  value: item.Redeemed ? item.Redeemed : 0,
                  subfix: this.lbl['dashboard-suffix-times'],
                },
                {
                  key: 'Used',
                  text: this.lbl['dashboard-redeem-by'],
                  value: item.Used ? item.Used : 0,
                  subfix: this.lbl['members'],
                },
              ],
            })
          })
          this.$forceUpdate()
        })
        .catch(err => {
          console.log(err)
        })
    },
    getBranchRank(date = [moment().subtract(30, 'days'), moment()]) {
      BzbsDashboard.getBranchRank({
        startdate: date[0].format('YYYY-MM-DD'),
        enddate: date[1].format('YYYY-MM-DD'),
      })
        .then(res => {
          let branchRank = res.data.data.slice(0, 5)
          this.rankListData[1].data = []
          branchRank.forEach((item, index) => {
            this.rankListData[1].data.push({
              rank: index + 1,
              img: '',
              showImg: false,
              badge: [],
              text: item.BranchName,
              adon: [
                {
                  key: 'GivePoint',
                  text: this.lbl['dashboard-transactions-earn-points'],
                  value: item.GivePoint ? item.GivePoint : 0,
                },
                {
                  key: 'Redeemed',
                  text: this.lbl['dashboard-insight-user-history-redeem-tab'],
                  value: item.Redeemed ? item.Redeemed : 0,
                  subfix: this.lbl['dashboard-suffix-times'],
                },
                {
                  key: 'ActiveMember',
                  text: this.lbl['dashboard-active-member'],
                  value: item.ActiveMember ? item.ActiveMember : 0,
                  subfix: this.lbl['members'],
                },
              ],
            })
          })
          this.$forceUpdate()
        })
        .catch(err => {
          console.log(err)
        })
    },
    exportPointChart() {
      if (this.crmPackage && this.crmPackage.IsFreePackage) {
        this.showAlertUpgradepackage = true
        this.alertUpgradeTitle =
          this.lbl['alert-upgrade-package-for-transactions-text']
        this.actionUpgrade = {
          internalPage: true,
          pageName: 'Package',
        }
      } else {
        this.exportFile(this.pointChartData, 'pointChartData')
      }
    },
    mapExportData() {
      if (this.crmPackage && this.crmPackage.IsFreePackage) {
        this.showAlertUpgradepackage = true
        this.alertUpgradeTitle =
          this.lbl['alert-upgrade-package-for-insights-text']
        this.actionUpgrade = {
          internalPage: true,
          pageName: 'Package',
        }
      } else {
        Object.keys(this.mainChartData).forEach(key => {
          console.log(key, this.mainChartData[key])
          this.exportFile(this.mainChartData[key], key)
        })
      }
    },
    exportFile(data, name) {
      const dataWS = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, name + '_' + Date.parse(new Date()) + '.xlsx')
    },
    beforeinit() {
      Account.getSubscriptionInfo().then(info => {
        if (info.length > 0) {
          if (info[0].SubscriptionId) {
            this.subscriptionId = info[0].SubscriptionId
          } else {
            this.subscriptionId = null
          }
        } else {
          this.subscriptionId = null
        }
      })
      this.businessName = ''
      if (Account.getCacheProfileAddress().strBusinessName) {
        this.businessName = Account.getCacheProfileAddress().strBusinessName
      } else if (Account.getCacheUser().BusinessName) {
        this.businessName = Account.getCacheUser().BusinessName
      }

      let configSelfOnBoard = false
      try {
        let parse = JSON.parse(localStorage.getItem('bzbsCRMConfig'))
        if (parse) {
          configSelfOnBoard = parse.buzzebeesConfigSelfOnBoard
            ? parse.buzzebeesConfigSelfOnBoard
            : false
        } else {
          configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
        }
      } catch (e) {
        configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
      }

      if (configSelfOnBoard) {
        Account.apiAccountInformation().then(res => {
          console.log('res:: ', res)
          if (res.data.RequirePackage) {
            if (this.$route.query.changepackage) {
              console.log(
                'res.data.PackageChange == null: ',
                res.data.PackageChange == null,
              )
              console.log(
                'res IsPackageChangeAcknowledged:2: ',
                res.data.PackageChange,
              )

              if (
                res.data.PackageChange == null ||
                res.data.PackageChange.IsPackageChangeAcknowledged
              ) {
                Account.apiAccountInformation()
                this.crmPackage = Account.getCachePackage()
                this.checkPackageChange()
              }
              Account.apiAccountPermissionChange(res.data)
            }

            if (res.data.IsExtendExpired) {
              if (!this.businessName) {
                if (!Vue.bzbsConfig.businessRule.webFor.PH) {
                  this.$router.push({
                    name: 'Package',
                    query: { returnPath: 'AccountSetting2' },
                  })
                } else {
                  this.$router.push({
                    name: 'Package',
                    query: { returnPath: 'AccountSetting' },
                  })
                }
              } else {
                if (this.$route.query.changepackage) {
                  this.$router
                    .push({
                      name: 'Dashboard2',
                      query: {
                        changepackage: true,
                      },
                    })
                    .catch(err => {
                      console.log('router err', err)
                    })
                } else {
                  this.$router
                    .push({
                      name: 'Dashboard2',
                    })
                    .catch(err => {
                      console.log('router err', err)
                    })
                }
              }
            } else {
              if (!this.businessName) {
                if (!Vue.bzbsConfig.businessRule.webFor.PH) {
                  this.$router.push({ name: 'AccountSetting2' })
                } else {
                  this.$router.push({ name: 'AccountSetting' })
                }
              } else {
                if (this.$route.query.changepackage) {
                  this.$router
                    .push({
                      name: 'Dashboard2',
                      query: {
                        changepackage: true,
                      },
                    })
                    .catch(err => {
                      console.log('router err', err)
                    })
                } else {
                  this.$router
                    .push({
                      name: 'Dashboard2',
                    })
                    .catch(err => {
                      console.log('router err', err)
                    })
                }
              }
            }
          } else {
            if (this.$route.query.changepackage) {
              console.log(
                'res.data.PackageChange == null: ',
                res.data.PackageChange == null,
              )
              console.log(
                'res IsPackageChangeAcknowledged:2: ',
                res.data.PackageChange,
              )

              if (
                res.data.PackageChange == null ||
                res.data.PackageChange.IsPackageChangeAcknowledged
              ) {
                Account.apiAccountInformation()
                this.crmPackage = Account.getCachePackage()
                this.checkPackageChange()
              }
              Account.apiAccountPermissionChange(res.data)
            }

            if (!this.businessName) {
              if (!Vue.bzbsConfig.businessRule.webFor.PH) {
                this.$router.push({ name: 'AccountSetting2' })
              } else {
                this.$router.push({ name: 'AccountSetting' })
              }
            } else {
              if (this.$route.query.changepackage) {
                this.$router
                  .push({
                    name: 'Dashboard2',
                    query: {
                      changepackage: true,
                    },
                  })
                  .catch(err => {
                    console.log('router err', err)
                  })
              } else {
                this.$router
                  .push({
                    name: 'Dashboard2',
                  })
                  .catch(err => {
                    console.log('router err', err)
                  })
              }
            }
          }
          Account.bzbsAnalyticTracking(
            'home_page',
            'home',
            'view_home',
            'on view',
          )
          this.init()
          this.countLoading = 0
          this.countLoadingBox = 0
        })
      } else {
        this.init()
        this.countLoading = 0
        this.countLoadingBox = 0
      }
    },
    init() {
      this.handleDashboard(true)
      this.handleLoading(true)
      this.handleFooter(true)
      this.crmPackage = Account.getCachePackage()
      this.profileConfig = Account.getCacheProfileConfig()

      console.log('profileConfig : ', this.profileConfig)
      let configSelfOnBoard = false
      try {
        let parse = JSON.parse(localStorage.getItem('bzbsCRMConfig'))
        if (parse) {
          configSelfOnBoard = parse.buzzebeesConfigSelfOnBoard
            ? parse.buzzebeesConfigSelfOnBoard
            : false
        } else {
          configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
        }
      } catch (e) {
        configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
      }

      if (configSelfOnBoard) {
        this.checkPackageChange().then(res => {
          console.log('checkPackageChange :: ', res)
          if (this.$route.query.isWelcomeBack) {
            if (
              this.compareCurrentDate(localStorage.getItem('stampDateWelcome'))
            ) {
              this.passExpirePackage(true)
            } else {
              localStorage.setItem('stampDateWelcome', new Date())
              setTimeout(() => {
                this.welcomeBack.title = this.lbl['selfonboard-welcomeback']
                this.welcomeBack.details =
                  this.lbl['selfonboard-welcomeback-details']
                this.welcomeBack.isOpenModal = true
                this.welcomeBack.onCancel = () => {
                  this.welcomeBack.isOpenModal = false
                  this.passExpirePackage(true)
                }
              }, 500)
            }
          } else if (this.$route.query.isChangePackage) {
            if (this.crmPackage) {
              this.packageChanged.title = this.lbl['package-changed']
              this.packageChanged.details =
                'CRM PLUS ' + this.crmPackage.PackageName
              this.packageChanged.lifeTime = this.crmPackage.RemainingDay
              let date = moment
                .unix(this.crmPackage.ConsumerExtendExpireDate)
                .format('DD/MM/YYYY')
              var imgHistoryId
              var imgCurrentId

              if (
                PackageHelper.filterPackageByPremium(
                  this.crmPackage.PackageChange.PreviousPackage.PackageId,
                ) ||
                PackageHelper.filterPackageByBasic(
                  this.crmPackage.PackageChange.PreviousPackage.PackageId,
                )
              ) {
                imgHistoryId =
                  this.crmPackage.PackageChange.PreviousPackage.PackageId.toLowerCase() +
                  '_' +
                  this.crmPackage.PackageChange.PreviousPackage.PackagePlanType.toLowerCase()
              } else {
                imgHistoryId = 'free'
              }
              if (
                PackageHelper.filterPackageByPremium(
                  this.crmPackage.PackageChange.NewPackage.PackageId,
                ) ||
                PackageHelper.filterPackageByBasic(
                  this.crmPackage.PackageChange.NewPackage.PackageId,
                )
              ) {
                imgCurrentId =
                  this.crmPackage.PackageChange.NewPackage.PackageId.toLowerCase() +
                  '_' +
                  this.crmPackage.PackageChange.NewPackage.PackagePlanType.toLowerCase()
              } else {
                imgCurrentId = 'free'
              }
              Account.apiAccountInformation().then(res => {
                Account.apiAccountPermissionChange(res.data)
              })

              this.packageChanged.payment = date
              this.packageChanged.imgHistory =
                Vue.bzbsConfig.bzbsBlobUrl +
                `/config/crmplus/package/logo_${imgHistoryId}.jpg` +
                `?v=` +
                new Date().getTime()
              this.packageChanged.imgCurrent =
                Vue.bzbsConfig.bzbsBlobUrl +
                `/config/crmplus/package/logo_${imgCurrentId}.jpg` +
                `?v=` +
                new Date().getTime()
              this.packageChanged.isOpenModal = true
              this.packageChanged.onView = () => {
                this.packageChanged.isOpenModal = false
                if (this.crmPackage) {
                  if (
                    PackageHelper.filterPackageByPremium(
                      this.crmPackage.PackageId,
                    )
                  ) {
                    this.$router.push('/View/Package/Premium')
                  } else {
                    this.$router.push('/View/Package/Basic')
                  }
                } else {
                  this.$router.push('/View/Package/Basic')
                }
              }
              this.packageChanged.onCancel = () => {
                this.apiAcknowledgePackageChange()
                this.packageChanged.isOpenModal = false
                this.passExpirePackage(true)
              }
            } else {
              this.passExpirePackage(true)
            }
          } else {
            if (this.crmPackage) {
              if (
                this.crmPackage.RequirePackage &&
                this.crmPackage.HasSelectedPackage
              ) {
                this.isPackage = true

                if (
                  this.crmPackage.RemainingDay <= 7 &&
                  this.crmPackage.IsFreePackage
                ) {
                  let payment = this.lbl['selfonboard-continuous-payment']
                  let close =
                    this.lbl['selfonboard-line-consumer-close-down-after']
                  let date = moment
                    .unix(this.crmPackage.ExpireDate)
                    .format('DD/MM/YYYY')
                  let line =
                    close +
                    ' ' +
                    date +
                    ' - ' +
                    '<span style="color: red">' +
                    this.crmPackage.RemainingDay +
                    ' ' +
                    this.lbl['selfonboard-day-left'] +
                    '</span>'
                  let details = payment + '<br /><br />' + line

                  if (this.crmPackage && this.crmPackage.IsFreePackage) {
                    setTimeout(() => {
                      this.expirePackage.title =
                        this.lbl['selfonboard-package-is-ending']
                      this.expirePackage.details = details
                      this.expirePackage.modalType = 'warning'
                      this.expirePackage.isOpenModal = true
                      this.expirePackage.backto =
                        this.lbl['confirm-box-cancel-button']
                      this.expirePackage.confirm =
                        this.lbl['selfonboard-buy-now']
                      this.expirePackage.onConfirm = () => {
                        this.expirePackage.isOpenModal = false
                        this.$router.push({ name: 'Package' })
                      }
                      this.expirePackage.onCancel = () => {
                        this.expirePackage.isOpenModal = false
                        this.passExpirePackage(true)
                      }
                    }, 500)
                  } else {
                    this.passExpirePackage(true)
                  }
                } else if (
                  this.crmPackage.ExtendRemainingDay <= 7 &&
                  !this.crmPackage.IsStandardPackage
                ) {
                  if (this.crmPackage.IsCorporatePackage) {
                    // ไม่มีปุ่มซื้อทันที
                    let payment = this.lbl['selfonboard-continuous-payment']
                    let close =
                      this.lbl['selfonboard-line-consumer-close-down-after']
                    let date = moment
                      .unix(this.crmPackage.ExtendExpireDate)
                      .format('DD/MM/YYYY')
                    let line =
                      close +
                      ' ' +
                      date +
                      ' - ' +
                      '<span style="color: red">' +
                      this.crmPackage.ExtendRemainingDay +
                      ' ' +
                      this.lbl['selfonboard-day-left'] +
                      '</span>'
                    let details = payment + '<br /><br />' + line
                    setTimeout(() => {
                      this.expirePackage.title =
                        this.lbl['selfonboard-package-is-ending']
                      this.expirePackage.details = details
                      this.expirePackage.modalType = 'warning'
                      this.expirePackage.isOpenModal = true
                      this.expirePackage.showbackto = false
                      this.expirePackage.backto =
                        this.lbl['confirm-box-cancel-button']
                      this.expirePackage.confirm = null
                      this.expirePackage.onConfirm = () => {
                        this.expirePackage.isOpenModal = false
                      }
                      this.expirePackage.onCancel = () => {
                        this.expirePackage.isOpenModal = false
                        this.passExpirePackage(true)
                      }
                    }, 500)
                  } else {
                    let payment = this.lbl['selfonboard-continuous-payment']
                    let close =
                      this.lbl['selfonboard-line-consumer-close-down-after']
                    let date = moment
                      .unix(this.crmPackage.ExtendExpireDate)
                      .format('DD/MM/YYYY')
                    let line =
                      close +
                      ' ' +
                      date +
                      ' - ' +
                      '<span style="color: red">' +
                      this.crmPackage.ExtendRemainingDay +
                      ' ' +
                      this.lbl['selfonboard-day-left'] +
                      '</span>'
                    let details = payment + '<br /><br />' + line
                    setTimeout(() => {
                      this.expirePackage.title =
                        this.lbl['selfonboard-package-is-ending']
                      this.expirePackage.details = details
                      this.expirePackage.modalType = 'warning'
                      this.expirePackage.isOpenModal = true
                      this.expirePackage.backto =
                        this.lbl['confirm-box-cancel-button']
                      this.expirePackage.confirm = this.lbl['selfonboard-renew']
                      this.expirePackage.onConfirm = () => {
                        this.expirePackage.isOpenModal = false
                        this.handleViewPaymentDetails()
                      }
                      this.expirePackage.onCancel = () => {
                        this.expirePackage.isOpenModal = false
                        this.passExpirePackage(true)
                      }
                    }, 500)
                  }
                } else if (
                  this.crmPackage.ExtendRemainingDay <= 7 &&
                  this.crmPackage.IsStandardPackage
                ) {
                  if (this.crmPackage.IsCancelPackage) {
                    let payment = this.lbl['selfonboard-continuous-payment']
                    let close =
                      this.lbl['selfonboard-line-consumer-close-down-after']
                    let date = moment
                      .unix(this.crmPackage.ExtendExpireDate)
                      .format('DD/MM/YYYY')
                    let line =
                      close +
                      ' ' +
                      date +
                      ' - ' +
                      '<span style="color: red">' +
                      this.crmPackage.ExtendRemainingDay +
                      ' ' +
                      this.lbl['selfonboard-day-left'] +
                      '</span>'
                    let details = payment + '<br /><br />' + line
                    setTimeout(() => {
                      this.expirePackage.title =
                        this.lbl['selfonboard-package-is-ending']
                      this.expirePackage.details = details
                      this.expirePackage.modalType = 'warning'
                      this.expirePackage.isOpenModal = true
                      this.expirePackage.backto =
                        this.lbl['confirm-box-cancel-button']
                      this.expirePackage.confirm =
                        this.lbl['selfonboard-buy-now']
                      this.expirePackage.onConfirm = () => {
                        this.expirePackage.isOpenModal = false
                        this.gotoSelectPackage()
                      }
                      this.expirePackage.onCancel = () => {
                        this.expirePackage.isOpenModal = false
                        this.passExpirePackage(true)
                      }
                    }, 500)
                  } else {
                    if (this.crmPackage.IsCorporatePackage) {
                      // ไม่มีปุ่มซื้อทันที
                      let payment = this.lbl['selfonboard-continuous-payment']
                      let close =
                        this.lbl['selfonboard-line-consumer-close-down-after']
                      let date = moment
                        .unix(this.crmPackage.ExtendExpireDate)
                        .format('DD/MM/YYYY')
                      let line =
                        close +
                        ' ' +
                        date +
                        ' - ' +
                        '<span style="color: red">' +
                        this.crmPackage.ExtendRemainingDay +
                        ' ' +
                        this.lbl['selfonboard-day-left'] +
                        '</span>'
                      let details = payment + '<br /><br />' + line
                      setTimeout(() => {
                        this.expirePackage.title =
                          this.lbl['selfonboard-package-is-ending']
                        this.expirePackage.details = details
                        this.expirePackage.modalType = 'warning'
                        this.expirePackage.isOpenModal = true
                        this.expirePackage.backto =
                          this.lbl['confirm-box-cancel-button']
                        this.expirePackage.showbackto = false
                        this.expirePackage.backto =
                          this.lbl['confirm-box-cancel-button']
                        this.expirePackage.confirm = null
                        this.expirePackage.onConfirm = () => {
                          this.expirePackage.isOpenModal = false
                        }
                        this.expirePackage.onCancel = () => {
                          this.expirePackage.isOpenModal = false
                          this.passExpirePackage(true)
                        }
                      }, 500)
                    } else {
                      let payment = this.lbl['selfonboard-continuous-payment']
                      let close =
                        this.lbl['selfonboard-line-consumer-close-down-after']
                      let date = moment
                        .unix(this.crmPackage.ExtendExpireDate)
                        .format('DD/MM/YYYY')
                      let line =
                        close +
                        ' ' +
                        date +
                        ' - ' +
                        '<span style="color: red">' +
                        this.crmPackage.ExtendRemainingDay +
                        ' ' +
                        this.lbl['selfonboard-day-left'] +
                        '</span>'
                      let details = payment + '<br /><br />' + line
                      setTimeout(() => {
                        this.expirePackage.title =
                          this.lbl['selfonboard-package-is-ending']
                        this.expirePackage.details = details
                        this.expirePackage.modalType = 'warning'
                        this.expirePackage.isOpenModal = true
                        this.expirePackage.backto =
                          this.lbl['confirm-box-cancel-button']
                        this.expirePackage.confirm =
                          this.lbl['selfonboard-renew']
                        this.expirePackage.onConfirm = () => {
                          this.expirePackage.isOpenModal = false
                          this.handleViewPaymentDetails()
                        }
                        this.expirePackage.onCancel = () => {
                          this.expirePackage.isOpenModal = false
                          this.passExpirePackage(true)
                        }
                      }, 500)
                    }
                  }
                } else {
                  this.passExpirePackage(true)
                }
              } else {
                this.passExpirePackage(true)
              }
            } else {
              this.passExpirePackage(true)
            }
          }
        })
      } else {
        this.passExpirePackage(true)
      }
    },
    handleViewPaymentDetails() {
      console.log('handleViewPaymentDetails')
      if (this.subscriptionId) {
        var params = {
          return_url: '/Account/Package?shoppingCart=true',
          success_url: '/Account/Package?shoppingCart=true',
          IsStandardPackage: this.crmPackage.IsStandardPackage
            ? this.crmPackage.IsStandardPackage
            : false,
        }
        BzbsCart.goShopingCartOrder(params, this.subscriptionId)
      } else {
        this.gotoSelectPackage()
      }
    },
    gotoSelectPackage() {
      this.$router.push({ name: 'Package' })
    },
    apiAcknowledgePackageChange() {
      return new Promise((resolve, reject) => {
        BzbsPackage.apiAcknowledgePackageChange()
          .then(res => {
            this.$router.replace({
              path: this.$router.path,
            })
            this.$router.go(0)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    checkPackageChange() {
      return new Promise(resolve => {
        this.getNonStandardPackage().then(() => {
          if (this.crmPackage) {
            if (
              this.crmPackage.PackageChange !== null &&
              this.crmPackage.PackageChange.IsPackageChangeAcknowledged == false
            ) {
              const currentId =
                this.crmPackage.PackageChange.PreviousPackage.PackageId.toLowerCase()

              if (this.nonStandardPackageList.includes(currentId)) {
                setTimeout(() => {
                  this.welcomeBack.title = this.lbl['selfonboard-welcomeback']
                  this.welcomeBack.details =
                    this.lbl['selfonboard-welcomeback-details']
                  this.welcomeBack.isOpenModal = true
                  this.welcomeBack.onCancel = () => {
                    localStorage.setItem('stampDateWelcome', new Date())
                    this.apiAcknowledgePackageChange()

                    this.welcomeBack.isOpenModal = false
                    this.passExpirePackage(true)
                  }
                }, 500)
              } else {
                if (this.$route.query.changepackage) {
                  this.$router.replace({
                    path: this.$router.path,
                    query: {
                      changepackage: true,
                      isChangePackage: 'true',
                      timestamp: Date.now(),
                    },
                  })
                } else {
                  this.$router.replace({
                    path: this.$router.path,
                    query: {
                      isChangePackage: 'true',
                      timestamp: Date.now(),
                    },
                  })
                }
              }
              resolve(true)
            } else {
              this.passExpirePackage(true)
              resolve(false)
            }
          }
        })
      })
    },
    getNonStandardPackage() {
      return new Promise(resolve => {
        BzbsPackage.apiGetNonStandardPackage()
          .then(res => {
            var packageData = res.data
            this.nonStandardPackageList = packageData.map(item =>
              item.PackageId.toLowerCase(),
            )
            resolve(res.data)
          })
          .catch(error => {
            console.log(error)
            resolve(error)
          })
      })
    },
    passExpirePackage(res) {
      if (res) {
        var loginType =
          Account.getCacheProfileAddress().strLoginType.toLowerCase()
        if (!Vue.bzbsConfig.businessRule.webFor.PH && loginType == 'line') {
          this.checkSetupLineConnect()
        } else {
          this.passNotify(true)
        }
      }
    },
    checkSetupLineConnect() {
      if (Account.getCacheProfileAddress().strLineLoginChannelId) {
        if (this.compareCurrentDate(localStorage.getItem('stampDateWelcome'))) {
          this.passNotify(true)
        } else {
          setTimeout(() => {
            this.welcomeBack.title = this.lbl['selfonboard-welcomeback']
            this.welcomeBack.details =
              this.lbl['selfonboard-welcomeback-details']
            this.welcomeBack.isOpenModal = true
            this.welcomeBack.onCancel = () => {
              this.welcomeBack.isOpenModal = false
              localStorage.setItem('stampDateWelcome', new Date())
              this.passNotify(true)
            }
          }, 500)
        }
      } else {
        this.openNotificationAccountSetting()
      }
    },
    passNotify(res) {
      if (this.profileConfig.ShownAnnouncement) {
        if (res) {
          if (
            this.compareCurrentDate(
              localStorage.getItem('stampDateAnnouncement'),
            )
          ) {
            this.passAnnouncement(true)
          } else {
            localStorage.setItem('stampDateAnnouncement', new Date())
            setTimeout(() => {
              this.callAnnouncement()
            }, 250)
          }
        }
      } else {
        this.passAnnouncement(true)
      }
    },
    onCancelAnnouncement(res) {
      console.log('onCancelAnnouncement : ', res)
      this.passAnnouncement(true)
    },
    passAnnouncement(res) {
      console.log('passAnnouncement : ', res)
    },
  },
}
</script>

<style lang="scss" scoped>
.pd-chart {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 90px;
  padding-bottom: 90px;
  border-radius: 12px;
  /* border: 1px solid var(--grey-grey-lighten-2, #E0E0E0); */
}

.hello {
  color: var(--text-light-text-headline, #303030);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.lastupdate {
  color: var(--red-red-5, #ff4d4f);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  img {
    filter: invert(0%) sepia(94%) saturate(2%) hue-rotate(180deg)
      brightness(250%) contrast(101%);
  }
}
</style>

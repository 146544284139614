<template>
  <div
    v-if="this.$router.currentRoute.path == '/Dashboard' || open"
    class="counter mt-3"
    :class="customclass"
  >
    <div
      v-for="i in Object.values(data)"
      :key="i.title"
      class="counteritem"
      :class="[i.class]"
    >
      <div class="title">
        <a-tooltip>
          <template slot="title">
            {{ i.info ? i.info : i.title }}
          </template>
          <img :src="getImage('dashboard', i.icon)" alt="" />
        </a-tooltip>
        {{ i.title }}
      </div>

      <div class="value">
        <span class="number mr-2">
          {{ i.value ? i.value.toLocaleString() : 0 }}
        </span>
        <span class="et">{{ i.et }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
export default {
  name: 'counterbox',
  props: {
    data: {
      type: Object,
    },
    open: {
      type: Boolean,
    },
    customclass: {
      type: String,
    },
  },
  data() {
    return {
      startTimestamp: {},
      num: {},
    }
  },
  mixins: [Mixin],
  mounted() {
    this.trickerCounter()
  },
  methods: {
    trickerCounter() {
      this.num = {}
      this.startTimestamp = {}
      Object.values(this.data).forEach(i => {
        this.counterAnim(0, i.value, 4000, i.title)
      })
    },
    counterAnim(start, end, time, key) {
      window.requestAnimationFrame(e => this.step(e, start, end, time, key))
    },
    step(timestamp, start, end, time, key) {
      if (!this.startTimestamp[key]) this.startTimestamp[key] = timestamp
      const progress = Math.min(
        (timestamp - this.startTimestamp[key]) / time,
        1,
      )
      this.num[key] = Math.floor(progress * (end - start) + start)
      this.$forceUpdate()
      if (progress < 1) {
        window.requestAnimationFrame(e => this.step(e, start, end, time, key))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.counter {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  .counteritem {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid var(--grey-grey-lighten-2, #e0e0e0);
    background: var(--surface-primary, #fff);
    color: var(--text-light-text-title, var(--text-text-title, #4a4a4a));

    &.span2 {
      grid-column: span 2;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;

      img {
        margin-right: 12px;
      }
    }

    .number {
      font-size: 24px;
      font-weight: 500;
      line-height: 120%;
    }

    .et {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.15px;
    }
  }
}

@media screen and (max-width: 1020px) {
  .counter {
    grid-template-columns: repeat(2, 1fr);

    .counteritem {
      &.span2 {
        grid-column: span 1;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .counter {
    grid-template-columns: repeat(1, 1fr);
  }
}
.hide {
  display: none;
}
.flex {
  display: flex;
  flex-wrap: wrap;

  .flex-max {
    flex: 1;
  }
}
</style>

import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Cache from '@/helper/Cache.js'
// import moment from 'moment'

export default {
  KEY_COOKIE_USER: 'CRMUser',
  config: function () {
    return Vue.bzbsConfig
  },
  token: function () {
    return Cache.get(this.KEY_COOKIE_USER).Token
  },
  appId: function () {
    return Cache.get(this.KEY_COOKIE_USER).AppId
  },
  agency_id: function () {
    return Vue.$cookies.get(this.KEY_COOKIE_USER).AgencyId
      ? Vue.$cookies.get(this.KEY_COOKIE_USER).AgencyId
      : this.config().agency_id
  },
  getBanner() {
    const params = {
      appId: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
    }
    var strUrl = this.config().bzbsModuleUrl + '/crmplusdatasci/dashboardbanner'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  getSummaryDashboard() {
    const params = {
      appId: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
    }
    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusdatasci/summary_dashboard'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  getMemberChart(data) {
    const params = {
      appId: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
      startDate: data.startdate,
      endDate: data.enddate,
      filter: data.filter,
    }
    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusdatasci/dashboard_member'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  getGenderChart(data) {
    const params = {
      appId: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
      startDate: data.startdate,
      endDate: data.enddate,
      filter: data.filter,
    }
    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusdatasci/dashboard_gender'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  getAgeChart(data) {
    const params = {
      appId: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
      startDate: data.startdate,
      endDate: data.enddate,
      filter: data.filter,
    }
    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusdatasci/dashboard_age'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  getActiveMemberChart(data) {
    const params = {
      appId: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
      startDate: data.startdate,
      endDate: data.enddate,
      filter: data.filter,
    }
    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusdatasci/trend_active_user'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  getPointChart(data) {
    const params = {
      appId: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
      startDate: data.startdate,
      endDate: data.enddate,
      filter: data.filter,
    }
    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusdatasci/dashboard_point'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  getRewardRedemtionRank(data) {
    const params = {
      appId: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
      startDate: data.startdate,
      endDate: data.enddate,
    }
    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusdatasci/rewardredemptionrank'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  getBranchRank(data) {
    const params = {
      appId: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
      redeemDate: data.startdate,
      startDate: data.startdate,
      endDate: data.enddate,
    }
    var strUrl = this.config().bzbsModuleUrl + '/crmplusdatasci/branchrank'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
}

<template>
  <div class="merchantsetup">
    <div class="text">
      {{ lbl['dashboard-merchant-setup'] }}
    </div>
    <div class="setup">
      <a-tooltip v-for="i in data" :key="i.title">
        <template slot="title">{{ i.info ? i.info : i.title }}</template>
        <div :key="i.title" class="setupitem">
          <span>
            <span class="st1">{{ i.title }}</span> {{ i.value }}
          </span>
        </div>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'

export default {
  name: 'merchantsetup',
  mixins: [Mixin],
  props: {
    data: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
.merchantsetup {
  padding: 16px 30px;
  background: var(--surface-primary, #fff);

  .text {
    color: var(--grey-grey-darken-3, #424242);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  .setup {
    gap: 16px;
    margin-top: 8px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    .setupitem {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 12px;
      border-radius: 6px;
      border: 1px solid var(--grey-grey-lighten-4, #f5f5f5);
      background: var(--grey-grey-lighten-5, #fafafa);

      &:hover {
        box-shadow: 0px 0px 15px rgb(218, 218, 218);
      }

      .st1 {
        color: var(--text-light-text-body, var(--text-text-body, #636363));
        font-size: 14px;
        font-weight: 700;
        line-height: 150%;
      }
    }
  }
}
</style>

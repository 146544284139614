<template>
  <div class="rankitem">
    <div class="ranktitle">
      <div class="header">
        <img :src="getImage('dashboard', data.icon)" alt="" />{{ data.title }}
      </div>
      <div class="filterinput">
        <a-range-picker
          v-model="date"
          :disabled-date="disabledDate"
          :showToday="false"
          :ranges="ranges"
          @change="onChange"
          @ok="onOk"
        >
          <div class="inputdate">
            {{ date ? dateFormat() : 'Select Date' }}
            <img
              class="calendar"
              :src="getImage('dashboard', 'calendar')"
              alt=""
            />
          </div>
        </a-range-picker>
      </div>
    </div>
    <RankItem v-for="rank in data.data" :key="rank.rank" :data="rank" />
    <a-empty v-if="!data.data.length" class="mt-5" />
    <div
      class="seemore"
      v-if="data.data.length"
      @click="gotoquerystring(data.page, date)"
    >
      {{ lbl['user-permissions-see-more'] + '>' }}
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import moment from 'moment'
import Utility from '../../../helper/Utility'
import RankItem from './RankItem.vue'
export default {
  name: 'rankcontainer',
  components: {
    RankItem,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      ranges: Utility.ranges,
      date: [moment().subtract(30, 'days'), moment()],
    }
  },
  mixins: [Mixin],
  created() {
    this.data.getlist(this.date, this.data.icon)
  },
  methods: {
    checkBadge(key) {
      if (key === 'Active' || key === 'Inactive') {
        return true
      } else {
        return false
      }
    },
    gotoquerystring(page, date) {
      let datetime = [moment(date[0]).unix(), moment(date[1]).unix()]
      this.goto(page, { data: datetime })
    },
    onChange() {
      this.data.getlist(this.date)
    },
    onOk() {
      // this.data.getlist(this.date);
    },
    disabledDate(current) {
      return current && current > moment().endOf('day')
    },
  },
}
</script>

<style lang="scss" scoped>
.rankcontainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(592px, 1fr));
  gap: 16px;

  .rankitem {
    position: relative;
    padding: 16px 16px 48px 16px;
    border-radius: 12px;
    border: 1px solid var(--grey-grey-lighten-2, #e0e0e0);
    background: var(--surface-primary, #fff);

    .ranktitle {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .header {
      display: flex;
      align-items: center;
      color: var(--text-light-text-title, var(--text-text-title, #4a4a4a));
      font-size: 16px;
      font-weight: 700;
      line-height: 150%;

      img {
        margin-right: 8px;
        border-radius: 8px;
        padding: 8px;
        background: var(--primary-gold-bzb-gold-9, #fff6e6);
      }
    }
    .filterinput {
      display: flex;
      align-items: center;
    }

    .seemore {
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      margin: 16px 0 8px 0;
      display: flex;
      justify-content: center;
      color: var(--primary-secoundary-color, #494949);
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
    }
  }
}
</style>

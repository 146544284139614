<template>
  <div class="dashboard">
    <div class="title">
      <div class="header d-flex align-items-center">
        <img :src="getImage('dashboard', 'usersthree')" alt="usersthree" />{{
          data.title
        }}
      </div>
      <div class="filterinput d-flex align-items-center">
        <a-range-picker
          v-model="date"
          :disabled-date="disabledDate"
          :showToday="false"
          :ranges="ranges"
          @change="onChange"
          @ok="onOk"
        >
          <div class="inputdate">
            {{ date ? dateFormat() : lbl['smart-target-select-date'] }}
            <img
              class="calendar"
              :src="getImage('dashboard', 'calendar')"
              alt="calendar"
            />
          </div>
        </a-range-picker>
        <slot name="button"></slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import moment from 'moment'
import Utility from '../../../helper/Utility'
export default {
  name: 'dashboardcontainer',
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      ranges: Utility.ranges,
      date: [moment().subtract(3, 'month'), moment()],
    }
  },
  mixins: [Mixin],
  methods: {
    onChange() {
      this.$emit('onChange', this.date)
    },
    onOk() {
      // this.$emit('onChange', this.date)
    },
    disabledDate(current) {
      return current && current > moment().endOf('day')
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard {
  margin-top: 16px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid var(--grey-grey-lighten-2, #e0e0e0);
  background: var(--surface-primary, #fff);

  > .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header,
    .filterinput {
      gap: 8px;
      flex-wrap: wrap;
    }
  }

  .header {
    color: var(--text-light-text-title, var(--text-text-title, #4a4a4a));
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;

    img {
      margin-right: 8px;
      border-radius: 8px;
      padding: 8px;
      background: var(--primary-gold-bzb-gold-9, #fff6e6);
    }
  }
}
</style>
<style lang="scss">
.ant-calendar-footer-extra {
  .ant-tag {
    color: #fa8c16 !important;
    background: #fff7e6 !important;
    border-color: #ffd591 !important;
  }
}

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  background: #fa8c16 !important;
  color: #fff !important;
}

.ant-calendar-range .ant-calendar-in-range-cell::before {
  background: #fff7e6 !important;
}

.ant-calendar-today .ant-calendar-date {
  color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}

.ant-calendar-date:hover {
  background: #fff7e6 !important;
}

.ant-calendar-header a:hover {
  color: #fa8c16 !important;
}

.inputdate {
  display: flex;
  position: relative;
  min-width: 200px;
  height: 40px;
  padding: 5px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 6px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  cursor: pointer;

  .calendar {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
</style>

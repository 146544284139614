<template>
    <div class="rankcontainer mt-4">
        <RankItemContainer v-for="rankitem in data" :key="rankitem.title" :data="rankitem" />
    </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import RankItemContainer from './RankItemContainer.vue'
export default {
    name: "rankcontainer",
    components: {
        RankItemContainer
    },
    props: {
        data: {
            type: Array,
        },
    },
    data() {
        return {
        }
    },
    mixins: [Mixin],
    methods: {
    },

}
</script>

<style lang="scss" scoped>
.rankcontainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(592px, 1fr));
    gap: 16px;

}
</style>
<template>
  <div class="rankbody">
    <div
      class="bodyitem"
      :class="{ open: data.open, warp: data.details, 'is-hide': data.open }"
    >
      <div class="rank">{{ data.rank }}</div>
      <div v-if="data.showImg && !data.details" class="img">
        <img
          v-if="data.img"
          :src="data.img"
          alt="campaign picture"
          width="58"
          height="58"
        />
      </div>
      <div class="detail">
        <div class="badge">
          <div
            v-for="badge in data.badge"
            :key="badge"
            class="type"
            :class="[badge]"
          >
            <span v-if="checkBadge(badge)" class="dot"></span>
            <span class="text">{{ badge }}</span>
          </div>
        </div>
        <a-tooltip :title="data.text">
          <div class="text">{{ data.text }}</div>
        </a-tooltip>
        <a-tooltip v-if="data.id" :title="lbl['dashboard-campaign-id-text']">
          <div class="text-id">{{ data.id }}</div>
        </a-tooltip>
      </div>
      <div class="result">
        <div
          v-for="adon in data.adon"
          :key="adon.text"
          class="adon"
          :class="{ show: !data.open }"
        >
          <div class="adtext">{{ adon.text }}</div>
          <div class="value">
            <span>
              {{
                adon.key == 'Quantity' &&
                adon.value &&
                parseInt(adon.value) >= 100000000
                  ? lbl['header-view-message-unlimited']
                  : adon.value.toLocaleString()
              }}
            </span>
            <span class="subfix">
              {{ adon.subfix ? adon.subfix : '' }}
            </span>
          </div>
        </div>
      </div>

      <img
        v-if="data.details"
        class="arrowdown"
        :class="{ rotate: data.open }"
        src="@/assets/images/Angle-down.svg"
        alt="arrowdown"
      />
      <div v-if="data.details" class="rankdetail">
        <CounterBox
          :data="data.details"
          :open="data.open"
          ref="CounterBox"
          :customclass="customclass"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import CounterBox from './CounterBox'
export default {
  name: 'rankcontainer',
  props: {
    data: {
      type: Object,
    },
    customclass: {
      type: String,
    },
  },
  components: {
    CounterBox,
  },
  mixins: [Mixin],
  watch: {
    data: {
      handler(newValue) {
        if (newValue.open) {
          this.$refs.CounterBox.trickerCounter()
        }
      },
      deep: true,
    },
  },
  methods: {
    checkBadge(key) {
      if (
        key === 'Active' ||
        key === 'Inactive' ||
        key === 'Published' ||
        key === 'Expired' ||
        key === 'Canceled'
      ) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.rankbody {
  margin-top: 16px;

  .bodyitem {
    padding: 8px;
    border-radius: 6px;
    border: 1px solid var(--neutral-4, #f0f0f0);
    background: var(--surface-primary, #fff);
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.5s ease-in-out;

    &.warp {
      flex-wrap: wrap;
      cursor: pointer;
    }

    &.open {
      max-height: 250px;
    }

    .rank {
      display: flex;
      padding: 10px 6px;
      height: 68px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;
      border-radius: 6px;
      border: 1px solid var(--neutral-4, #f0f0f0);
      background: var(--neutral-3, #f5f5f5);
    }

    .img {
      width: 58px;
      height: 58px;
      min-width: 58px;
      min-height: 58px;
      background-color: #636363;
    }

    .detail {
      margin-right: auto;
      overflow: hidden;
      .badge {
        display: flex;
        align-items: center;
        gap: 8px;

        .Active,
        .Published {
          display: flex;
          padding: 4px 8px;
          gap: 4px;
          align-items: center;
          border-radius: 20px;
          border: 1px solid var(--polar-green-2, #d9f7be);
          background: var(--polar-green-1, #f6ffed);

          .dot {
            width: 8px;
            height: 8px;
            background-color: #52c41a;
            border-radius: 50px;
          }

          .text {
            color: var(--green-green-1, #52c41a);
            font-size: 12px;
            font-weight: 400;
            line-height: 120%;
          }
        }

        .Inactive,
        .Expired,
        .Canceled {
          display: flex;
          padding: 4px 8px;
          align-items: center;
          gap: 4px;
          border-radius: 20px;
          border: 1px solid var(--text-light-text-disable, #b0b0b0);
          background: var(--grey-grey-lighten-5, #fafafa);

          .dot {
            width: 8px;
            height: 8px;
            background-color: #b0b0b0;
            border-radius: 50px;
          }

          .text {
            color: var(--text-light-text-disable, #b0b0b0);
            font-size: 12px;
            font-weight: 400;
            line-height: 120%;
          }
        }
        .type,
        .Delivery,
        .Free,
        .e-Voucher {
          display: flex;
          padding: 1px 6px;
          align-items: center;
          gap: 10px;
          border-radius: 3px;
          border: 1px solid var(--neutral-4, #f0f0f0);
          background: var(--neutral-3, #f5f5f5);

          .text {
            color: var(--text-light-text-body, var(--text-text-body, #636363));
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      .text {
        color: var(--grey-grey-2, var(--text-text-title, #4a4a4a));
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .text-id {
        color: #969696;
        font-size: 12px;
      }

      @media only screen and (max-width: 1322px) and(min-width: 1224px) {
        width: 145px;
        min-width: 145px;
        .text {
          max-width: 142px;
        }
      }
      @media (max-width: 710px) {
        width: 145px;
        min-width: 145px;
        .text {
          max-width: 142px;
        }
      }
    }
    .result {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      flex-wrap: nowrap;
    }

    .adon {
      height: 68px;
      min-width: 90px;
      width: auto;
      display: flex;
      padding: 4px;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      border-radius: 6px;
      background: var(--neutral-3, #f5f5f5);
      transition: all 0.5s ease-in-out;
      opacity: 0;

      &.show {
        opacity: 1;
      }

      &:first-child {
        margin-left: auto;
      }

      .adtext {
        color: var(--text-light-text-caption, #969696);
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }

      .value {
        color: var(--text-light-text-title, var(--text-text-title, #4a4a4a));
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
      }
      .subfix {
        color: #969696;
        font-size: 10px;
        font-weight: 400;
      }
    }

    .rankdetail {
      flex-grow: 1;
      width: 100%;
    }
  }
}
.rotate {
  transform: rotate(180deg);
}
</style>
